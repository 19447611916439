/* eslint-disable no-param-reassign */
var index = function (breakpoints) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    literal = _ref.literal,
    overlap = _ref.overlap;
  var mq = literal ? breakpoints : ['&'].concat(breakpoints);
  function flatten(obj) {
    if (typeof obj !== 'object' || obj == null) {
      return [];
    }
    if (Array.isArray(obj)) {
      return obj.map(flatten);
    }
    var slots = {};
    var objects = {};
    var props = {};
    Object.keys(obj).forEach(function (key) {
      // Check if value is an array, but skip if it looks like a selector.
      // key.indexOf('&') === 0

      var item = obj[key];
      if (!Array.isArray(item) && literal) item = [item];
      if ((literal || Array.isArray(item)) && key.charCodeAt(0) !== 38) {
        var prior = void 0;
        item.forEach(function (v, index) {
          // Optimize by removing duplicated media query entries
          // when they are explicitly known to overlap.
          if (overlap && prior === v) {
            return;
          }
          if (v == null) {
            // Do not create entries for undefined values as this will
            // generate empty media media quries
            return;
          }
          prior = v;
          if (index === 0 && !literal) {
            props[key] = v;
          } else if (slots[mq[index]] === undefined) {
            var _slots$mq$index;
            slots[mq[index]] = (_slots$mq$index = {}, _slots$mq$index[key] = v, _slots$mq$index);
          } else {
            slots[mq[index]][key] = v;
          }
        });
      } else if (typeof item === 'object') {
        objects[key] = flatten(item);
      } else {
        props[key] = item;
      }
    });

    // Ensure that all slots and then child objects are pushed to the end
    mq.forEach(function (el) {
      if (slots[el]) {
        props[el] = slots[el];
      }
    });
    Object.assign(props, objects);
    return props;
  }
  return function () {
    for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }
    return values.map(flatten);
  };
};
export default index;
