import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    standalone: false
})
export class NotFoundComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    public spinner: NgxSpinnerService,
  ) {
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());
  }

  ngOnInit(): void {
    this.spinner.hide();
  }
}
